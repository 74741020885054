<template>
  <Modal :style="computedWindowSize"
         :visible="visible"
         class="p-fluid"
         @close="close">
    <template v-slot:header>
      <div v-if="!item.id" class="p-dialog-title">{{ $t('Adding a new service') }}</div>
      <div v-else class="p-dialog-title">{{ $t('Service editing') }}</div>
    </template>
    <Spinner v-if="dataIsSending"></Spinner>
    <div v-else class="p-formgrid p-grid p-pt-3">
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <CustomDropdown id="service_category_modal"
                          :class="{'p-invalid' : submitted && !selectedCategory}"
                          v-model="selectedCategory"
                          :options="categories"
                          :clearSearchData="!visible"
                          optionLabel="name"
                          :filter="true"
                          :showClear="true">
            <template #value="slotProps">
              <div v-if="slotProps.value">
                <span>{{ slotProps.value.name }}</span>
              </div>
              <span v-else style="visibility: hidden">.</span>
            </template>
            <template #option="slotProps">
              <div>
                <span>{{ slotProps.option.name }}</span>
              </div>
            </template>
          </CustomDropdown>
          <label for="service_category_modal">{{ $t('Category') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !selectedCategory">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="service_code_modal" v-model.trim="serviceData.code" autocomplete="off"/>
          <label for="service_code_modal">{{ $t('Code') }}</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">
        <div class="p-float-label">
          <InputText id="service_name_modal" :class="{'p-invalid' : submitted && !serviceData.name}" v-model.trim="serviceData.name" autocomplete="off"/>
          <label for="service_name_modal">{{ $t('Name') }}<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !serviceData.name">{{ $t('Required field') }}</small>
      </div>
<!--      <div class="p-field p-col-12 p-sm-12 p-md-12 p-lg-12 p-mb-5">-->
<!--        <div class="p-float-label">-->
<!--          <CustomDropdown id="service_area_modal"-->
<!--                          v-model="selectedArea"-->
<!--                          :options="areas"-->
<!--                          :clearSearchData="!visible"-->
<!--                          optionLabel="name"-->
<!--                          :filter="true"-->
<!--                          :showClear="true">-->
<!--            <template #value="slotProps">-->
<!--              <div v-if="slotProps.value">-->
<!--                <span>{{ slotProps.value.name }} <span v-if="slotProps.value.description">({{ slotProps.value.description }})</span></span>-->
<!--              </div>-->
<!--              <span v-else style="visibility: hidden">.</span>-->
<!--            </template>-->
<!--            <template #option="slotProps">-->
<!--              <div>-->
<!--                <span>{{ slotProps.option.name }} <span v-if="slotProps.option.description">({{ slotProps.option.description }})</span></span>-->
<!--              </div>-->
<!--            </template>-->
<!--          </CustomDropdown>-->
<!--          <label for="service_area_modal">{{ $t('Working area')}}</label>-->
<!--        </div>-->
<!--      </div>-->
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber :locale="computedNumberInputLocale" id="service_time_modal" v-model="serviceData.time" mode="decimal" :min="0" :max="99" :minFractionDigits="2" :maxFractionDigits="2" autocomplete="off"/>
          <label for="service_time_modal">Time ({{ $t('hours') }})</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-float-label">
          <InputNumber :locale="computedNumberInputLocale" id="service_sell_modal" :class="{'p-invalid' : submitted && !serviceData.sell}" v-model="serviceData.sell" mode="decimal" :min="0" :minFractionDigits="2" :maxFractionDigits="4" autocomplete="off"/>
          <label for="service_sell_modal">Sell price<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && !serviceData.sell">{{ $t('Required field') }}</small>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mb-5">
        <div class="p-d-flex p-ai-center">
          <div class="p-d-flex p-ai-center">
            <InputSwitch id="service_use_consumables_modal" v-model="serviceData.use_consumables"/>
          </div>
          <label for="service_use_consumables_modal" class="switch-label pointer">Use consumables</label>
        </div>
      </div>
      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6" v-if="serviceData.use_consumables">
        <div class="p-float-label">
          <InputNumber :inputClass="{'p-invalid' : submitted && (serviceData.use_consumables && !serviceData.consumables_sell)}"
                       :locale="computedNumberInputLocale"
                       id="service_consumables_sell_modal"
                       v-model="serviceData.consumables_sell"
                       :min="0"
                       :minFractionDigits="2"
                       :maxFractionDigits="3"
                       autocomplete="off"/>
          <label for="service_consumables_sell_modal">Consumables sell price<span class="warning-color">*</span></label>
        </div>
        <small class="p-invalid" v-if="submitted && (serviceData.use_consumables && !serviceData.consumables_sell)">{{ $t('Required field') }}</small>
      </div>
    </div>

<!--    !!!!!!!!!!!!!!!!!!"RECURRING" DON'T DELETE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->
<!--    <div class="p-d-flex p-ai-center">-->
<!--      <div>-->
<!--        <InputSwitch id="service_is_recurring_modal" v-model="serviceData.is_recurring"/>-->
<!--      </div>-->
<!--      <label for="service_is_recurring_modal" class="switch-label pointer">Is is_recurring</label>-->
<!--    </div>-->
<!--    <div class="p-formgrid p-grid" v-if="serviceData.is_recurring">-->
<!--      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mt-5">-->
<!--        <div class="p-float-label">-->
<!--          <InputNumber :class="{'p-invalid' : submitted && (serviceData.is_recurring && !serviceData.mileage_step)}" id="service_mileage_step_modal" v-model="serviceData.mileage_step" :min="0" autocomplete="off"/>-->
<!--          <label for="service_mileage_step_modal">Шаг пробега, км<span class="warning-color">*</span></label>-->
<!--        </div>-->
<!--        <small class="p-invalid" v-if="submitted && (serviceData.is_recurring && !serviceData.mileage_step)">{{ $t('Required field') }}</small>-->
<!--      </div>-->
<!--      <div class="p-field p-col-12 p-sm-6 p-md-6 p-lg-6 p-mt-5">-->
<!--        <div class="p-float-label">-->
<!--          <InputNumber :class="{'p-invalid' : submitted && (serviceData.is_recurring && !serviceData.time_step)}" id="service_time_step_modal" v-model="serviceData.time_step" :min="0" autocomplete="off"/>-->
<!--          <label for="service_time_step_modal">Шаг времени, дней<span class="warning-color">*</span></label>-->
<!--        </div>-->
<!--        <small class="p-invalid" v-if="submitted && (serviceData.is_recurring && !serviceData.time_step)">{{ $t('Required field') }}</small>-->
<!--      </div>-->
<!--    </div>-->
    <!--    !!!!!!!!!!!!!!!!!!"RECURRING" DON'T DELETE!!!!!!!!!!!!!!!!!!!!!!!!!!!!!-->

    <template #footer>
      <Button :label="$t('Cancel')" icon="pi pi-times" class="p-button-text" @click="close"/>
      <Button :label="$t('Save')" :disabled="disableSaveButton" icon="pi pi-check" class="p-button-text" @click="saveItem" />
    </template>
  </Modal>
</template>

<script>
import httpClient from '@/services/http.services'
import httpMixins from "@/mixins/httpMixins";
import formatMixins from "@/mixins/formatMixins";
import settings from "@/settings";

export default {
  mixins: [ httpMixins, formatMixins ],
  emits: ['close', 'update-items', 'update-item'],
  name: 'ServiceModal',
  props: {
    item: Object,
    categories: Array,
    areas: Array,
    visible: Boolean,
  },
  data() {
    return {
      dataIsSending: false,
      disableSaveButton: false,
      selectedArea: null,
      selectedCategory: null,
      isRecurring: false,
      useConsumable: false,
      serviceData: {},
      submitted: false
    }
  },
  watch: {
    item(value) {
      this.serviceData = { ...value }

      this.serviceData.time = value.time ? +value.time : null
      this.serviceData.use_consumables = value.use_consumables ? true : false
      this.serviceData.is_recurring = value.is_recurring ? true : false
      this.serviceData.purchase = value.purchase ? +value.purchase : null
      this.serviceData.sell = value.sell ? +value.sell : null
      this.serviceData.consumables_sell = value.consumables_sell ? +value.consumables_sell : null

      // if (value.area) {
      //   this.selectedArea = value.area
      // }

      if (value.service_category_id) {
        const selectedCategory = this.categories.filter(category => category.id === +value.service_category_id)[0]
        if (selectedCategory) {
          this.selectedCategory = selectedCategory
        }
      }
    },
    visible() {
      if (!this.visible) {
        this.submitted = false
        this.selectedCategory = null
        // this.selectedArea = null
      }
    },
  },
  methods: {
    // changeCategory() {
    //   this.serviceData.categoryWasChanged = this.serviceData.service_category_id !== this.selectedCategory?.id
    // },
    async saveItem() {
      this.submitted = true
      if (!this.serviceData.name || !this.serviceData.sell || !this.selectedCategory || (this.serviceData.use_consumables && !this.serviceData.consumables_sell) || (this.serviceData.is_recurring && (!this.serviceData.mileage_step || !this.serviceData.time_step))) {
        return false
      }

      this.disableSaveButton = true
      this.dataIsSending = true

      const service = {
        id: this.serviceData.id ?? null,
        code: this.serviceData.code ? this.serviceData.code : null,
        name: this.serviceData.name ? this.serviceData.name : null,
        // area_id: this.selectedArea ? +this.selectedArea.id : null,
        service_category_id: this.selectedCategory ? this.selectedCategory.id : null,
        time: this.serviceData.time ? this.serviceData.time : null,
        purchase: this.serviceData.purchase ? this.serviceData.purchase : null,
        sell: this.serviceData.sell ? this.serviceData.sell : null,
        use_consumables: this.serviceData.use_consumables ? 1 : 0,
        consumables_sell: this.serviceData.consumables_sell ? this.serviceData.consumables_sell : null,
        is_recurring: this.serviceData.is_recurring ? 1 : 0,
        mileage_step: this.serviceData.mileage_step ? this.serviceData.mileage_step : null,
        time_step: this.serviceData.time_step ? this.serviceData.time_step : null,
      }

      if (this.serviceData.id) {
        this.serviceData.isNew = false
        try {
          const { status } = await httpClient.post(`service/update-service`, service)
          if (status === 200) {
            this.$emit('update-items', this.serviceData.id)
            this.$emit('update-item', this.serviceData)
            this.$toast.add({severity: 'success', detail: this.$t('Data updated'), life: settings.toastLife});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      } else {
        try {
          const { status, data } = await httpClient.post(`service/create-service`, service)
          if (status === 201 && data) {
            this.serviceData.id = data
            service.id = data
            this.serviceData.isNew = true
            this.$emit('update-items', this.serviceData.id)
            this.$emit('update-item', this.serviceData)
            this.$toast.add({severity:'success', detail: this.$t('Data saved'), life: settings.toastLife});
            this.close()
          }
        } catch(err) {
          this.showError(err)
        } finally {
          this.dataIsSending = false
          this.disableSaveButton = false
        }
      }
    },
    close() {
      this.$emit('close')
      this.submitted = false
    },
  },
  computed: {
    computedWindowSize() {
      if (this.$store.state.mobileLayout) {
        return {position: 'absolute', top: 0, width: '95%', marginTop: '10px'}
      } else if (!this.$store.state.mobileLayout && this.$store.state.tabletLayout) {
        return {position: 'absolute', top: 0, width: '550px', marginTop: '15px'}
      } else {
        return {position: 'absolute', top: 0, width: '550px'}
      }
    },
  },

}
</script>

<style scoped lang="scss">

</style>